import { GatsbySeo } from 'gatsby-plugin-next-seo'
import ServicesPage from 'src/components/servicos/ServicesPage'

function Servicos() {
  return (
    <>
      <GatsbySeo title="Serviços | Decathlon" noindex nofollow />
      <ServicesPage />
    </>
  )
}

export default Servicos
